<template>
    <el-checkbox-group v-model="value" @change="handleChange">
        <!-- <pre>{{value}}</pre>-->
      <el-checkbox-button :label="item.value" :key="item.value" v-for="item in options">
        {{ item.label }}
      </el-checkbox-button> 
      <!-- <el-checkbox-button label="1">a</el-checkbox-button> -->
    </el-checkbox-group>
</template>

<script>

export default {
    data:()=>({
        value: [],
    }),
    watch:{
        modelValue(){
            this.value = this.modelValue
        }
    },
    created(){
        this.value = this.modelValue;
    },
    methods:{
        handleChange(){
            this.$emit('update:modelValue', this.value)
        }
    },
    props:{
        options:{
            type: Array,
        },
        modelValue:{
            type: Array,
        }
    }
}
</script>
