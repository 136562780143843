<style scoped>
.fixed-bar{
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    /* left: 140px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 18px; */
    height: 60px;
    z-index: 99;
    border-top: solid 1px #f7f7f7;
    padding: 0 10px;
    /* box-shadow: 0 4px 24px 0 ; */
}
</style>

<template>
    <div class="fixed-bar" :style="style">
        <slot></slot>
    </div>
</template>

<script>

export default {
    data:() => ({
        style:{
            left: '180px',
        }
    }),
    mounted(){
        // if(ResizeObserver) {
        //     let ele = document.querySelector('.page-sidebar');


        //     if(!ele) {
        //         this.style.left = 0;
        //         return
        //     }

        //     const resizeObserver = new ResizeObserver(entries => {
        //         for (let entry of entries) {
        //             console.log('entry.target.clientWidth', entry.target.clientWidth)
        //             this.style.left = entry.target.clientWidth + 'px'
        //         }
        //     });

        //     resizeObserver.observe(ele);
        // }
    }
}
</script>
