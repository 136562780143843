<style scoped>

</style>
<template>
    <el-color-picker class="color-picker" v-model="color" :predefine="predefineColors" @change="handleChange"></el-color-picker>
</template>

<script>

export default {
    data: ()=> ({
        color: '',
        predefineColors: [
            // '#ff4500',
            // '#ff8c00',
            // '#ffd700',
            // '#90ee90',
            // '#00ced1',
            // '#1e90ff',
            // '#c71585',
            // 'rgba(255, 69, 0, 0.68)',
            // 'rgb(255, 120, 0)',
            // 'hsv(51, 100, 98)',
            // 'hsva(120, 40, 94, 0.5)',
            // 'hsl(181, 100%, 37%)',
            // 'hsla(209, 100%, 56%, 0.73)',

            // '#c7158577',
            // // '#409EFF',
            // '#67C23A',
            // '#E6A23C',
            // '#FF6146',
            '#FF6146', // 主题色
            '#E2231A', // 价格颜色
            
            '#FA5151',
            '#C87D2F',
            '#FFC300',
            '#91D300',
            '#95EC69',
            '#07C160',
            '#10AEFF',
            '#1485EE',
            '#6467F0',
            '#576B95',

            '#fff',
            '#000',

            '#303133',
            '#606266',
            '#909399',
            '#A8ABB2',
            '#C0C4CC',

        ]
    }),
    watch:{
        modelValue:function(){
            this.color = this.modelValue;
        }
    },
    created(){
        this.color = this.modelValue;
    },
    methods:{
        handleChange(){
            this.$emit('update:modelValue', this.color)
            this.$emit('change', this.color)
        }
    },
    props:{
        modelValue:{
            type: String,
        }
    }
}
</script>
