<style scoped>
</style>
<template>
<!-- :close-on-click-modal="false" :close-on-press-escape="false" -->
    <!-- must destroy-on-close	 !!!-->
    <el-dialog v-model="dialog.visible" :title="dialog.title" :width="dialog.width || '460px'" align-center :show-close="!dialog.loading"
    :close-on-click-modal="!dialog.loading" :close-on-press-escape="!dialog.loading" append-to-body v-if="dialog.visible" class="dialog" :fullscreen="dialog.fullscreen" destroy-on-close lock-scroll>
        <el-scrollbar max-height="80vh" v-loading="dialog.loading" :element-loading-text="dialog.loading_text" :height="dialog.height || 'auto'">
            <slot></slot>
        </el-scrollbar>

        <template #footer>
            <slot name="footer">
                <el-button @click="close" :disabled="dialog.loading">取消</el-button>
                <el-button type="primary" @click="$emit('save')" :loading="dialog.loading">确定</el-button>
            </slot>
        </template>
    </el-dialog>
</template>

<script>

export default {
    data:() => ({
        dialog:{

        },
    }),
    created(){
        this.dialog = this.modelValue;
    },
    methods:{
        close(){
            this.dialog.visible = false
            this.$emit('close')
        }
    },
    props:{
        modelValue:{
            type: Object,
        },
    }
}
</script>
