<template>
    <el-select v-model="value" @change="handleChange" :multiple="multiple" filterable clearable @clear="handleClear">
        <el-option :label="item.label" :value="item.value" :key="item.value" v-for="item in options"/>
    </el-select>
</template>

<script>

export default {
    data:()=>({
        value: '',
    }),
    watch:{
        modelValue(){
            this.value = this.modelValue
        },
    },
    created(){
        this.value = this.modelValue;

        // dirty fix
        if(this.value == 0) {
            this.value = '';
        }

        if(this.options && this.options.length > 0 && !this.multiple && this.options.map(v => v.value).indexOf(this.value) == -1) {
            let a = this.options.filter(v => v.default == true)

            if(a && a.length > 0) {
                this.value = a[0].value;
                this.handleChange();
            }else{
                this.value = '';
            }
        }
    },
    methods:{
        handleClear(){

            // if(this.options.length > 0) {
            //     let a = this.options[0]

            //     if(typeof a.value == 'number') {
            //         this.value = 0;
            //         this.$emit('update:modelValue', 0)
            //         console.log('hello')
            //     }
            // }
        },
        handleChange(){
            this.$emit('update:modelValue', this.value)
            console.log('handleChange', this.value)
            // this.$emit('change', this.value)
        }
    },
    // emits:['change'],
    props:{
        options:{
            type: Array,
        },
        modelValue:{
            type: [String, Number, Array]
        },
        multiple:{
            type: Boolean,
        }
    }
}
</script>
