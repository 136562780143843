<template>
    <div style="display:inline-block">
        <div style="display:inline-block" @click="showDialog">
            <slot ></slot>
        </div>

        <el-dialog v-model="dialog.visible" v-loading="loading" align-center append-to-body title="分享信息" width="400px">
            <div>
                <img v-if="miniapp_qrcode" :src="miniapp_qrcode" style="display:block;margin:20px auto;width:200px;"/>

                <el-form style="text-align:center">
                    <!-- <a download="qrcode.png" style="margin-left:10px;" :href="miniapp_qrcode" target="_blank">
                        <el-button  type="primary" plain>下载二维码</el-button>
                    </a> -->
                    <Select v-model="query.type" style="max-width: 100px;" :options="[
                    {
                        label: '小程序码',
                        value: 1,
                    },
                    {
                        label: '二维码',
                        value: 2,
                    }
                    ]" @change="fetchInfo" size="small"></Select>

                    <Select v-model="query.width" :options="[
                    {
                        label: '430x430',
                        value: 430,
                    },
                    {
                        label: '860x860',
                        value: 860,
                    },
                    {
                        label: '1280x1280',
                        value: 1280,
                    }
                    ]" style="max-width: 120px;margin:0 6px;" @change="fetchInfo" size="small"></Select>

                    <el-button  @click="handleDownload" type="primary" plain size="small">下载</el-button>

                    <div style="margin-top:10px;">
                        <el-button ref="copy-apppid" @click="copyAppID">复制AppID</el-button>
                        <el-button ref="copy-path" @click="copyPath">复制路径</el-button>

                        <el-button v-if="miniapp_url"
                        ref="copy-link"  type2="primary" @click="copyLink">复制网页链接</el-button>
                    </div>
                </el-form>
            </div>

            <template #footer>
                <!-- <el-button @click="dialog.visible = false">关闭</el-button> -->
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {getApi} from '@/plugin/http'

export default {
    expose:['showDialog'],
    data: () => ({
        // qrcode_base64: '',
        loading: false,
        dialog:{
            visible: false,
        },
        miniapp_qrcode: '',
        miniapp_path: '',
        miniapp_appid: '',
        miniapp_url: '',
        query:{
            type: 1,
            width: 430,
        }
    }),
    created: function(){
        // this.fetchInfo();
    },
    watch:{
        // path: function(){
        //     this.fetchInfo();
        // }
    },
    computed:{
        // img_url: function(){
        //     return 'data:image/png;base64,' + this.qrcode_base64
        // }
    },
    methods: {
        showDialog(){
            this.dialog.visible = true;
            this.fetchInfo();
        },
        fetchInfo: function(){
            this.loading = true
            getApi('/admin/shop/shop/share_info', Object.assign({
                path: this.path,
            }, this.query)).then((re) => {
                let data = re.data
                this.miniapp_qrcode = data.miniapp_qrcode
                this.miniapp_path = data.miniapp_path
                this.miniapp_appid = data.miniapp_appid
                this.miniapp_url = data.miniapp_url

                // if(data.miniapp_qrcode) {
                //     var xhr = new XMLHttpRequest();
                //     xhr.onload = function() {
                //         var reader = new FileReader();
                //         reader.onloadend = function() {
                //             // callback(reader.result);
                //             this.miniapp_qrcode_base64 = reader.result
                //         }
                //         reader.readAsDataURL(xhr.response);
                //     };
                //     xhr.open('GET', url);
                //     xhr.responseType = 'blob';
                //     xhr.send();
                // }

            }).catch(this.$handleError).finally(() => {
                this.loading = false
            });
        },
        copyAppID(){
            this.$copyText(this.miniapp_appid, this.$refs['copy-apppid'].$el).then( () => {
                this.$message.success('小程序AppID已复制');
            }).catch(()=> {
                this.$message.error('复制失败，请手动复制哦～');
            })
        },
        copyPath: function(){
            this.$copyText(this.miniapp_path, this.$refs['copy-path'].$el).then( () => {
                this.$message.success('小程序路径已复制');
            }).catch(()=> {
                this.$message.error('复制失败，请手动复制哦～');
            })
        },
        copyLink(){
            this.$copyText(this.miniapp_url, this.$refs['copy-link'].$el).then( () => {
                this.$message.success('网页链接已复制');
            }).catch(()=> {
                this.$message.error('复制失败，请手动复制哦～');
            })
        },
        handleDownload(){
            location.href = this.miniapp_qrcode + '&download=1';
            // if(this.isMiniprogram) {
            //     let url = BASE_URL + '/api/store/qrcode_show.png?path=' + encodeURIComponent(this.path)
            //     location.href = url
            //     // url = 'https://static.wxapp.kilotree.cn/contact_me_qr_t.png'
            //     // window.wx.previewImage({
            //     //     urls:[url],
            //     //     current: url,
            //     //     fail: alert,
            //     //     success: console.log,
            //     // })
            // }
        },
        // download: function(){
        //     window.location.href = 'data:application/octet-stream;base64,' + this.qrcode_base64;
        // }
    },
    props:{
        path:{
            type: String,
        }
    }
}
</script>
