// const $ = window.$

export const BASE_URL = process.env.NODE_ENV == 'development' ? '//atomix.kilotree.com' : '//atomix.kilotree.cn'

// 跨域携带 cookie
// $.ajaxSettings.xhrFields = {
//     withCredentials: true,
// }
   
// var logoutHandlers = []
// export function handleLogout(handler) {
//     logoutHandlers.push(handler)
// }

function ApiError(re, uri) {
    this.name = process.env.NODE_ENV == 'development' ? uri : '';
    this.message = re.error_msg || '接口请求错误～';
    this.error_code = re.error_code
    // this.noLogin = re.error_code == 1618
    this.stack = (new Error()).stack;
    // this.toString = () => this.message

    // 掉登事件处理
    switch(re.error_code) {
        case 10010:
            if(['/app'].indexOf((new URL(location.href)).pathname) == -1){
                location.href = "/app"
            }
            break;
        case 10086:
            if([
                // '/renew', 
                '/index',
                '/login', 
                '/rule/user-rule', 
                '/rule/privacy-rule', 
                '/contact',
            ].indexOf((new URL(location.href)).pathname) == -1){
                location.href = "/login?back_url=" + encodeURIComponent(location.href)
            }
            break;
    }
}

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;
// ApiError.prototype.toString = () => 

function request(uri, params, method = 'GET') {
    return new Promise(function(resolve, reject){

        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.open(method, method == 'GET' ? buildUrl(uri, params) : buildUrl(uri));
        
        let headers = buildReqeustHeaders();
        for(let k of Object.keys(headers)) {
            xhr.setRequestHeader(k, headers[k])
        }

        xhr.onerror = function(e){
            console.log('xhr error', e)
            reject(e);
        }

        xhr.onload = function() {
            // console.log('xhr onload')
            try{
                if (xhr.status != 200) {
                    reject(new ApiError({
                        error_msg: 'HTTP Error: ' + xhr.status,
                    }, uri));
                    return;
                }

                // console.log('re', xhr)
                if(!xhr.responseText) {
                    return reject(new ApiError({
                        error_msg: '返回数据为空', // e.message
                    }, uri));
                }

                const re = JSON.parse(xhr.responseText);
                
                if(re.error_code) {
                    
                    return reject(new ApiError(re, uri));
                }

                // console.log('ok', re)
                resolve(re);
            }catch(e) {
                let log_uri = '/api/kit/report/report_error'
                if(uri != log_uri) {
                    
                    // console.log('xhr', xhr)
                    request(log_uri, {
                        res: xhr.responseText,
                        uri: uri,
                    }, 'POST');

                    console.log(e)
                    reject(new ApiError({
                        error_msg: '无法解析返回数据', // e.message
                    }, uri));
                }
            }            
        };
        
        let formData = null
        if(method == 'POST') {
            let conent_type = 'json';
            for(let k of Object.keys(params || {})) {
                if(params[k] instanceof Blob) {
                    conent_type = 'form';
                    break;
                }
            }

            if(conent_type == 'form') {
                formData = new FormData();

                let append = function(v, k = ''){

                    if(!v) {
                        return
                    }

                    if(typeof v == 'object' && v instanceof Blob != true ) {
                        for(let _k of Object.keys(v)) {
                            let _v = v[_k]
                            append(_v, k ? k+`[${_k}]`: _k)
                        }
                    }else{
                        formData.append(k, v);
                    }
                }

                append(params);
            }else{
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
                formData = JSON.stringify(params);
            }
        }

        xhr.send(formData);

        // $.ajax({
        //     url: BASE_URL +  uri,
        //     type: method,
        //     data: data,
        //     dataType: 'json',
        //     headers: buildReqeustHeaders(),
        //     complete: function(xhr) {
        //         try{
        //             // console.log('re', xhr)
        //             if(!xhr.responseText) {
        //                 return reject(new ApiError({
        //                     error_msg: '返回数据为空', // e.message
        //                 }, uri));
        //             }

        //             const re = JSON.parse(xhr.responseText);
                    
        //             if(re.error_code) {
                        
        //                 return reject(new ApiError(re, uri));
        //             }

        //             // console.log('ok', re)
        //             resolve(re);
        //         }catch(e) {
        //             let log_uri = '/api/log/req_err.json'
        //             if(uri != log_uri) {
                        
        //                 // console.log('xhr', xhr)
        //                 request(log_uri, {
        //                     res: xhr.responseText,
        //                     uri: uri,
        //                 }, 'POST');

        //                 reject(new ApiError({
        //                     error_msg: '无法解析返回数据', // e.message
        //                 }, uri));
        //             }
        //         }
        //     }
        // });
    })
}

export function buildReqeustHeaders(){
    return {
        'Authorization': 'Bearer ' + getToken(),
        'Accept': 'application/json',
        'X-App-Id': getAppId() || '',
        'X-App-Type': 'shop', // TODO
    }
}

export function getApi(uri, data = null){
    return request(uri, data)
}

export function postApi(uri, data = null){
    return request(uri, data, 'POST')
}

export function buildUrl(url, params, with_token) {
    if(!url) {
        return alert('url is empty')
    }

    if(with_token === true) {
        params['X-Access-Token'] = getToken();
    }

    if(url.indexOf('?') == -1) {
        url += '?'
    }else{
        url += '&'
    }

    return BASE_URL + url + buildQuery(params)
}

export function getAppId()
{
    return localStorage.getItem('app_id')
}

export function setAppId(app_id){
    localStorage.setItem('app_id', app_id)
}

export let http = {
    get: getApi,
    post: postApi,
    setAppId,
}

function buildQuery(params){

    if(! params) {
        return '';
    }

    let esc = encodeURIComponent;
    return Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
}

export function getToken()
{
    return localStorage.getItem('access_token')
}

export function saveToken(access_token){
    localStorage.setItem('access_token', access_token)
}

let getCacheResult = {};
let getCacheCallback = {};
export function getApiOnce(uri, params, callback)
{
    function cb(re){
        return callback(Object.assign({}, re));
    }

    let cache_key = buildUrl(uri, params)
    
    if(getCacheResult[cache_key]) {
        const re = getCacheResult[cache_key];
        return cb(re, re);
    }
    
    if(typeof getCacheCallback[cache_key] == 'undefined')
    {
        getCacheCallback[cache_key] = [cb];
        getApi(uri, params).then((re)=>{
            getCacheResult[cache_key] = re;
            for(let i in getCacheCallback[cache_key])
            {
                getCacheCallback[cache_key][i](re, re);
            }
        })
    }else{
        getCacheCallback[cache_key].push(cb);
    }
}