<template>
    <div style="margin-left:10px;width:100%;display:flex;align-items:center;">
        <el-slider v-model="value" @change="handleChange" :show-input="showInput" :min="min" :max="max"/>
        <div style="margin-left:4px;">{{unit}}</div>
    </div>
</template>

<script>

export default {
    data: ()=> ({
        value: '',
    }),
    created(){
        this.value = parseInt(this.modelValue);
    },
    methods:{
        handleChange(){
            this.$emit('update:modelValue', this.value + this.unit);
        }
    },
    props:{
        modelValue:{
            type: String,
        },
        unit:{
            type: String,
        },
        showInput:{
            type: Boolean,
        },
        min:{
            type: Number,
        },
        max:{
            type:Number,
            default: 100,
        }
    }
}
</script>
