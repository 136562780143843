<template>
    <el-radio-group v-model="value" @change="handleChange">
      <component :is="type == 'button' ? 'el-radio-button' : 'el-radio' "  :label="item.value" :key="item.value" v-for="item in options">
        {{ item.label }}
      </component> 
      
      <!-- <el-radio :label="item.value" :key="item.value" v-for="item in options" v-else>
        {{ item.label }}
      </el-radio>  -->
    </el-radio-group>
</template>

<script>

export default {
    data:()=>({
        value: [],
    }),
    watch:{
        modelValue(){
            this.value = this.modelValue
        }
    },
    created(){
        this.value = this.modelValue;
        console.log('rg create', this.value, this.options)
        if(this.options.map(v => v.value).indexOf(this.value) == -1) {
            let a = this.options.filter(v => v.default == true)

            if(a && a.length > 0) {
                this.value = a[0].value;
                this.handleChange();
            }
        } 
    },
    mounted(){
        
    },
    methods:{
        handleChange(){
            this.$emit('update:modelValue', this.value)
        }
    },
    props:{
        options:{
            type: [Array],
        },
        modelValue:{
            type: [Array, Number, String],
        },
        type:{
            type: String,
        }
    }
}
</script>
