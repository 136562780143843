import * as Vue from 'vue'
// const { createApp, reactive, createVNode, render} = Vue
import { createApp, reactive, createVNode, render } from 'vue'

// import { createApp, Vue, reactive } from 'vue'
// import { createVNode, render} from 'vue'

import { createPinia, mapActions, mapState } from 'pinia'

// import Vue from 'vue'

// import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import {ElImageViewer} from 'element-plus'
import { ClickOutside } from 'element-plus'

import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import Gallery from '@/component/main/Gallery'
import ProductDialog from '@/component/shop/ProductDialog'
import UpdateUserCreditDialog from '@/component/shop/UpdateUserCreditDialog'

import {http} from '@/plugin/http'
import {dateFormat} from '@/plugin/index'
import { AppStore } from '@/store/app'

// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
function createComponent ({ app, component, props, el }) {
  
    let expose = null
    
    const childApp = createApp({ render: () => expose = createVNode(component, props) })
  
    Object.assign(childApp._context, app._context) 
  
    childApp.mount(el)
  
    return expose.component.exposed
}

const pinia = createPinia()

let pageShowCallbackList = {}
let handlePageShow = function(){
	if(!document['hidden']) {
        console.log('page show')
        
        // 刷新登录信息
        AppStore().freshAppStore();

		for(let i in pageShowCallbackList) {
			if(typeof pageShowCallbackList[i] == 'function') {
				pageShowCallbackList[i]();
			}
		}
	}
}

let app = createApp({
	template:'<router-view></router-view>',
	mounted(){
        this.freshAppStore();
		document.addEventListener('visibilitychange', handlePageShow)
	},
    // updated: function(to, from, next){
    //     this.freshAppStore();
    //     next()
    // }, 
	unmounted(){
		document.removeEventListener('visibilitychange', handlePageShow)
	}
})

// app.use()
app.use(pinia);
app.directive('clickOutside', ClickOutside)

for (const [key, component] of Object.entries(window.ElementPlusIconsVue || ElementPlusIconsVue)) {
    app.component(key, component)
}

import Dialog from '@/component/common/Dialog'
import ColorPicker from '@/component/common/ColorPicker'
import Slider from '@/component/common/Slider'
import MainFooterBar from '@/component/common/MainFooterBar'
import Select from'@/component/common/Select'
import CheckboxGroup from'@/component/common/CheckboxGroup'
import RadioGroup from'@/component/common/RadioGroup'
import ScrollBox from'@/component/common/ScrollBox'

import ShareDialog from'@/component/shop/ShareDialog'

app.component('Gallery', Gallery)
app.component('Dialog', Dialog)
app.component('ColorPicker', ColorPicker)
app.component('Slider', Slider)
app.component('MainFooterBar', MainFooterBar)
app.component('Select', Select)
app.component('CheckboxGroup', CheckboxGroup)
app.component('RadioGroup', RadioGroup)
app.component('ScrollBox', ScrollBox)

console.log('app keys', typeof Vue)

app.mixin({
    data: () => ({
        isMobile: window.isMobile,
        // predefinecolor
    }),
    mounted(){
        let handler = this.$options.methods['handlePageShow']
        let uid = this.$.uid
        // if(this.$options.name == 'notice')
        // console.log('this.$options.name', this.$options.name, this.$options, typeof handler,this.$)
        // console.log('this._uid',this, this.$._uid, typeof handler)

        if(typeof handler == 'function' && uid) {
            console.log('reg', this.$options.name)
            pageShowCallbackList[uid] = () => {
                handler.apply(this);
            }
        }
    },
    unmounted(){
        delete(pageShowCallbackList[this.$.uid])
    },
    computed:{
        ...mapState(AppStore, ['app_info', 'user_info', 'staff_info']),
        dateFormat(){
            return (d, f = 'Y-m-d H:i:s') => {
                console.log('dateFormat', d)
                return dateFormat(f, d);
            }
        },
        fmtAmount(){
            return amount => {
                if(!amount) {
                    return '0.00';
                }

                let f = String(amount/100).split('.');
                if(!f[1]) {
                    f[1] = []
                }
                
                while(f[1].length < 2) {
                    f[1] += '0';
                }

                return f[0] + '.' + f[1]
            };
        },
    },
    methods:{
        ...mapActions(AppStore, ['hasPrivilege', 'freshAppStore', 'logoutStaff']),
    },
});

// import {http} from '@/plugin/http'
// http.get('/admin/account/account_info')
// .then(re => {
//     let data = re.data

//     app.provide('account_info', {
//         user_info: data.user_info,
//         app_list: data.app_list,
//     })
// })
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

app.use(ElementPlus, {
      locale: zhCn,
    // locale: window.ElementPlusLocaleZhCn,
}).use(router).use({
    install(app) {
        // AppStore.freshAppStore();
        app.config.globalProperties.$http = http
        app.config.globalProperties.$dateFormat = dateFormat

        app.config.globalProperties.$handleError = (e) => {
            console.log('error', e)
            app.config.globalProperties.$message.error(String(e))
        }

        app.config.globalProperties.$copyText = (text, container) => {

            return new Promise((resolve, reject) => {
                if(!window.ClipboardJS) {
                    console.log('window.ClipboardJS not loaded.')
                    return reject('缺少复制组件')
                }

                let fakeElement = document.createElement('button')
                let clipboard = new window.ClipboardJS(fakeElement, {
                    text: function () {
                        return text
                    },
                    action: function () {
                        return 'copy'
                    },
                    container: typeof container === 'object' ? container : document.body,
                })
                
                clipboard.on('success', function () {
                    clipboard.destroy()
                    // callback(undefined, e)
                    resolve()
                })
                
                clipboard.on('error', function (e) {
                    clipboard.destroy()
                    reject(e);
                    // callback(e, undefined)
                })
    
                // document.body.appendChild(fakeElement)
    
                fakeElement.click()
                
                // document.body.removeChild(fakeElement)
            });
        }
        // 未用过
        app.config.globalProperties.$showProductDialog = opt => {
            if(!opt) opt = {}

            let container = document.createElement('div')

            let vnode = createVNode(ProductDialog, {
                show: true,
                onClose(){
                    render(null, container)
                    container.remove();
                },
                onDestroy(){
                    console.log('onDestroy')
                    render(null, container)
                }
            })

            render(vnode, container)

            document.body.appendChild(container)
        }

        app.config.globalProperties.$shopUpdateUserCredit = opt => {

            if(!opt) opt = {}

            let container = document.createElement('div')

            return new Promise((resolve, reject) => {
                createApp(UpdateUserCreditDialog, Object.assign({
                    show: true,
                    success: resolve,
                    fail: reject,
                }, opt)).use(ElementPlus).mount(container)
                
                document.body.appendChild(container)
            });
        }

        app.config.globalProperties.$showGallery = opt => {
            if(!opt) opt = {}
            let container = document.createElement('div')

            let el = createComponent({
                app: app,
                component: Gallery,
                props: reactive({
                    onChange(res){
                        opt.change(res);
                        // console.log('success', arguments)
                    }
                }),
                el: container,
            });

            el.showGallery();

            return document.body.appendChild(container.firstElementChild);
        }

        app.config.globalProperties.$previewImage = (opt) => {
            if(!opt) opt = {}
            // let comp = createApp(ElImageViewer)
            // comp.mount('#preview-image')
            let container = document.createElement('div')

            let vnode = createVNode(ElImageViewer, {
                'url-list': opt.urls,
                'hide-on-click-modal': true,
                'initial-index': opt.current,
                onClose(){
                    render(null, container)
                    container.remove();
                },
                onDestroy(){
                    console.log('onDestroy')
                    render(null, container)
                }
            })
            // let instance = getCurrentInstance()
            // let ele = document.createDocumentFragment()
            // vnode.appContext = app.appContext
            render(vnode, container)

            document.body.appendChild(container)
        }

        app.config.globalProperties.$switchApp = (item) => {

            if(!item.app_id) {
                return app.$message.error('无法跳转');
            }

            http.setAppId(item.app_id);

            // 全部用子域名 ${item.app_type}
            let home_path = '/'
            
            if(window.Atomix) {
                // 带域名的
                location.href = window.Atomix.getHomePath();
            }else{
                router.push(home_path);
            }

            AppStore().freshAppStore();
        }

        app.config.globalProperties.$showShareDialog = opt => {
            if(!opt) opt = {}
            let container = document.createElement('div')

            let el = createComponent({
                app: app,
                component: <ShareDialog></ShareDialog>,
                props: reactive({
                    path: opt.path,
                }),
                el: container,
            });

            el.showDialog();

            return document.body.appendChild(container.firstElementChild);
        }

        // this.$showDialog(, 'SkuAttr');
        // app.config.globalProperties.$showDialog = (opt, name) => {
        //     if(!opt) opt = {}

        //     let container = document.createElement('div')
        //     // let Component = () => import('@/component/dialog/'+name+'Dialog')
        //     let map = {
        //         'shop/SkuAttr': () => import('@/component/dialog/'+name+'Dialog'),
        //     }

        //     let vnode = createVNode(map[name], Object.assign({}, opt, {
        //         onClose(v){
        //             render(null, container)
        //             container.remove();

        //             if(opt.close) {
        //                 opt.close(v)
        //             }
        //         },
        //     }))

        //     render(vnode, container)

        //     document.body.appendChild(container)
        // }
    }
}).mount('#app')
