import { defineStore } from 'pinia'
import {http} from '@/plugin/http'
// export const mapState = mapState;
// export const mapActions = mapActions;
// import { ElLoading, ElMessageBox } from 'element-plus'

export const AppStore = defineStore('App', { // Public项目唯一id
    state: () => {
        return {
            // userMsg: {},
            staff_info:{},
            app_info:{},
            user_info:{},
        }
    },
    getters: {
        // getUserMsg: (state) => {
        //     return state.userMsg
        // },
        // hasPrivilege: (state) => {
        //     return (privilege_id) => {
        //         if(state.app_info.staff_id && state.staff_info.staff_id == state.app_info.staff_id) {
        //             return true;
        //         }

        //         let id_arr = state.staff_info.privilege_list.map(v => v.privilege_id)
        //         return id_arr.indexOf(privilege_id) != -1;
        //     }
        // }
    },
    actions:{
        // initAppStore(staff_info, app_info){ // 废弃
        //     this.staff_info = staff_info
        //     this.app_info = app_info
        // },
        setStaffInfo(staff_info){
            this.staff_info = staff_info
        },
        setAppInfo(app_info){
            this.app_info = app_info;
        },
        setUserInfo(user_info){
            this.user_info = user_info
        },
        freshAppInfo(){
            let app_id = this.app_info.app_id
            http.get('/admin/main/account/app_info', {
                app_id,
            }).then(re => {
                let data = re.data
                this.app_info = data.app_info;
            });
        },
        freshAppStore(){
            console.log('freshAppStore')
            http.get('/admin/main/account/login_info')
            .then(re => {
                let data = re.data
                this.staff_info = data.staff_info
                this.app_info = data.app_info
                this.user_info = data.user_info

                // try fix local data
                // localStorage.setItem('app_id', this.app_info.app_id)
            })
        },
        hasPrivilege(privilege_id){
            if(this.app_info.staff_id && this.staff_info.staff_id == this.app_info.staff_id) {
                return true;
            }

            let id_arr = this.staff_info && this.staff_info.privilege_list ? this.staff_info.privilege_list.map(v => v.privilege_id) : []

            return id_arr.indexOf(privilege_id) != -1;
        },
        logoutStaff(){
            // let loading = ElLoading.service({
            //     fullscreen: true,
            // });

            return http.post('/api/user/account/logout').then(() => {
                // this.$message.success('已成功退出登录')
 
                
                localStorage.clear();
                
                // dont unset below variable!
                // this.staff_info = null
                // this.user_info = null
                // this.app_info = null

                // this.$router.push('/login')

                // ElMessageBox({
                //     messsage: '已成功退出登录',
                //     type: 'success',
                // });
            })
            
            // .catch(e => {
            //     ElMessageBox({
            //         messsage: String(e),
            //         type: 'error',
            //     });
            // }).finally(() => {
            //     // this.loading = false
            //     loading.close();
            // })
        }
    },
    // other options...
})