<style scoped>
.container{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.main{
    flex:1;
    overflow-y: auto;
    /* padding:10px; */
}
.header{
    /* margin-top:10px; */
    padding: 10px;
    background: #fff;
    display: flex;
    border-bottom: solid 1px #f7f7f7;
}
.header:empty{
    display: none;
}
.footer{
    /* margin-top:10px; */
    padding: 18px 10px;
    background: #fff;
    display: flex;
    border-top: solid 1px #f7f7f7;
    box-sizing: border-box;
}
.footer:empty{
    display: none;
}
.main :deep(.el-card) {
    margin: 10px;
    min-height:calc(100% - 20px);
}

@media (max-width: 768px) { 
    .footer{
        overflow-x: auto; /** mobile**/
/*        flex-direction: column;*/
    }
}

</style>
<template>
    <div class="container scroll-box">
        <div class="header empty-hidden">
            <slot name="header">
             </slot>
        </div>

        <div class="main">
            <slot></slot>
        </div>
        <div class="footer empty-hidden">
            <slot name="footer">
             </slot>
        </div>
    </div>
</template>