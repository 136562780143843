<template>
    <el-dialog v-model="dialog.visible" title="调整积分" width="500px">
        <el-form label-width="auto">
            <!-- <el-form-item label="调整用户">
                <div style="display:flex;">
                    <el-avatar :src="user_info.avatar"/>
                    <div style="margin-left:10px;">{{user_info.nickname}}</div>
                </div>
            </el-form-item>

            <el-form-item label="当前积分">
                {{store_user.user_credit}}
            </el-form-item> -->

            <el-form-item label="调整方式">
                <el-radio v-model="form.update_type" label="1">增加</el-radio>
                <el-radio v-model="form.update_type" label="2">减少</el-radio>
            </el-form-item>

            <el-form-item label="调整数量">
                <el-input-number v-model="form.update_credit_count" style="max-width:120px;" controls-position="right" :min="1"></el-input-number>
                    积分
            </el-form-item>

            <template v-if="form.update_type == 1">
                <el-form-item label="积分时效">
                    <el-select v-model="form.end_type" style="max-width:200px;">
                            <el-option label="永久有效" :value="1"/>
                            <el-option label="有有效期" :value="2"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="有效期" v-if="form.end_type == 2">
                    <el-input v-model="form.end_time_fmt" style="max-width:100px;">
                        <template #suffix>
                            <div>天</div>
                        </template>
                    </el-input>
                </el-form-item>
            </template>

            <el-form-item label="备注原因">
                <el-input v-model="form.update_title" style="width:300px;" maxlength="20" show-word-limit placeholder="注明调整的原因" clearable/>
            </el-form-item>

            <!-- <el-form-item label="改后积分">
                {{getUpdatedCredit}}
            </el-form-item> -->
            <!-- <el-form-item label="短信通知" v-if="update_credit.update_type == 1 && store_vip && store_vip.vip_tel">
                <el-radio-group v-model="update_credit.send_sms">
                    <el-radio-button label="1">发送</el-radio-button>
                    <el-radio-button label="2">不发送</el-radio-button>
                </el-radio-group>
                <el-link target="_blank" href="/sms/recharge" style="margin-left:10px;" type="primary">短信充值</el-link>
            </el-form-item> -->
        </el-form>

        <template #footer>
            <el-button type="primary" @click="save">确定</el-button>
            <el-button @click="dialog.visible = false">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
import{postApi} from '@/plugin/http'

export default {
    data: () => ({
        dialog:{
            visible: true,
        },
        form:{
            update_type: '1',
            update_credit_count: 1,
            end_type: 1,
            end_time_fmt: 30,
        }
    }),
    methods:{
        save(){
            postApi('/admin/shop/user/update_user_credit', Object.assign({
                user_id: this.uid,
            }, this.form))
            .then(() => {
                // this.$message.success('操作成功');
                this.dialog.visible = false
                this.success && this.success();
            }).catch(this.fail);
        }
    },
    props:{
        uid:{
            type:[Array, String, Number]
        },
        show:{
            type: Boolean,
        },
        success:{
            type: Function,
        },
        fail:{
            type: Function
        }
    }
}
</script>
